import type { FormKitNode, FormKitFrameworkContext } from "@formkit/core"

/**
 * A function that allows you to use the focus functionality: when there's an invalid input, the viewport will be centered on it and the element focused
 *
 * @param {any} formRef - a "ref()" of a "FormKit" with type "form"
 * @param {boolean} useParent - a workaround since "FormKit"s with type "checkout" wont trigger the "scrollIntoView" and "focus" methods .. but their "parentElement" will
 * @returns an object with a "valid" boolean that returns true if the form has no errors and a "focusInvalidInputs" method to trigger e.g on any form submit
 */
export const useFocusInvalidInputs = (
  formRef: globalThis.Ref<FormKitFrameworkContext>,
  useParent?: boolean
) => {
  const valid = computed(() => !!formRef.value?.node?.context?.state?.valid)

  const scrollOptions: ScrollIntoViewOptions = {
    behavior: "smooth",
    block: "center"
  }

  const filterChildrenInputs = (
    children: FormKitNode[]
  ): HTMLElement | undefined => {
    const firstInvalidField = children.find(
      (input) => !input?.context?.state?.valid
    )

    if (!firstInvalidField || !firstInvalidField.context?.id) return

    if (firstInvalidField?.type !== "input") {
      return filterChildrenInputs(firstInvalidField.children as FormKitNode[])
    } else {
      const invalidInput = document.getElementById(firstInvalidField.context.id)
      return invalidInput ?? undefined
    }
  }

  const focusInvalidInputs = (): void => {
    if (!process.client || !!valid.value) return

    const node: FormKitNode = formRef.value?.node
    const childrenInputs = node?.children as FormKitNode[]
    const invalidInput = filterChildrenInputs(childrenInputs)

    if (!invalidInput) return

    if (useParent && invalidInput?.parentElement) {
      invalidInput.parentElement.scrollIntoView(scrollOptions)
      invalidInput.parentElement.focus()
    } else {
      invalidInput.scrollIntoView(scrollOptions)
      invalidInput.focus()
    }
  }

  const forceFocus = (name: string) => {
    const node = document.getElementsByName(name)?.[0]
    if (node) {
      node.focus()
      node.scrollIntoView(scrollOptions)
    }
  }

  return { valid, focusInvalidInputs, forceFocus }
}
